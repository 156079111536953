<template>
  <div class="main-container">
    <div>
      <h1 class="main-title">드론 교육원 홈</h1>
      <!-- <h5 class="pageTitle">안녕하세요, {{ userInfo.name }}님</h5> -->
      <br>
      <mdb-container class="digital-clock">
        <mdb-row>
        <mdb-col  class="date-column">
          <p>{{ currentDate }}</p>
        </mdb-col>
        <mdb-col  class="time-column">
          <p>{{ currentTime }}</p>
        </mdb-col>
      </mdb-row>
      </mdb-container>
      
      <mdb-container class="weather-block">
        <div>
          <div class="weather-header">
            <h6 class="logo-title">
              <img :src="require('@/assets/images/weather/logo.jpg')" alt="Logo" class="logo-image">
              기상청 제공
              <button @click="navigateToMap" class="location-button">위치설정</button>
            </h6>
            
          </div>                
          <h5 style="text-align: left;"> {{ weather.areaData.city }} {{ weather.areaData.gu }} {{weather.areaData.city_district}} {{ weather.areaData.dong }} 
            <!--  // 동이 두번나오는 지역이 있다.. 이경우 dong만 나오도록 ex> display_name: '흥도로, 도내동, 흥도동, 덕양구, 고양시, 10491, 대한민국' -->
            {{ weather.areaData.dong? '': weather.areaData.quarter }}
            {{ weather.areaData.county }}
            {{ weather.areaData.town }} {{ weather.areaData.village }}  </h5>
        </div>
        <div class="weather-info">
        <mdb-row >          
          <mdb-col >
            <h4 style="margin-bottom: 5px;">오늘 날씨</h4>                        
            <p style="font-size: 74px; font-style:oblique; font-weight: bold; color:black">
              <img :src="TodaygetSkyConditionImage(weather.today.skyCondition, new Date())" alt="weather icon">
              {{ weather.today.temperature }} °C
            </p>
            <p style="font-size:20px">{{ TodaygetSkyConditionName(weather.today.skyCondition) }}</p>
            <p style="font-size:20px">강수확률 {{ weather.today.precipitationProbability }}% ⸰ {{ weather.today.windDirection}} {{weather.today.windSpeed}}m/s</p>
            <p style="font-size:20px">일출 {{weather.today.sunrise}} ⸰ 일몰 {{weather.today.sunset}}</p>
          </mdb-col>
          <mdb-col >
            <h4>내일 날씨</h4>
            <mdb-row class="tomorrow-weather-row">
              <mdb-col class="weather-column">
                <div>
                  <h5>오전</h5>
                  <p style="font-size: 44px;">
                    <img :src="getTomorrowAmIcon()" alt="weather icon">
                    <!-- <img :src="require('@/assets/images/weather/w04-1.jpg')" alt="weather icon">                     -->
                    {{ weather.tomorrow.amTemp }} °C 
                  </p>
                  <p style="font-size:18px">{{ weather.tomorrow.amSkyName }} </p>
                  <p style="font-size:18px">강수확률 {{weather.tomorrow.amPrep }}%</p>
                </div>                
              </mdb-col>
              <div class="vertical-divider"></div>
              <mdb-col class="weather-column">
                <div>
                  <h5>오후</h5>
                  <p style="font-size: 44px;">
                    <img :src="getTomorrowPmIcon()" alt="weather icon">
                    <!-- <img :src="require('@/assets/images/weather/w41-1.jpg')" alt="weather icon">                     -->
                    {{ weather.tomorrow.pmTemp }} °C 
                  </p>
                  <p style="font-size:18px">{{ weather.tomorrow.pmSkyName }} </p>
                  <p style="font-size:18px">강수확률 {{ weather.tomorrow.pmPrep }}%</p>
                </div>                
              </mdb-col>
            </mdb-row>
            <p style="font-size:20px">일출 {{ weather.tomorrow.sunrise }} ⸰ 일몰 {{weather.tomorrow.sunset}}</p>
          </mdb-col>
        </mdb-row>    
      </div>
      </mdb-container>
      
    </div>
    <mdb-container class="home-container">
      
      <div class="card-section mb-4">
        <mdb-row>
          <mdb-col md="4">
            <mdb-card>
              <mdb-card-body>
                <h4 class="card-title">어제 수강생수</h4>
                <h2>15 명</h2>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
          <mdb-col md="4">
            <mdb-card>
              <mdb-card-body>
                <h4 class="card-title">어제 총비행시간</h4>
                <h2>6.3h</h2>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
          <mdb-col md="4">
            <mdb-card>
              <mdb-card-body>
                <h4 class="card-title">전년대비 월평균 수강생 증감률</h4>
                <h2>+30%</h2>                
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </div>
      <div class="card-section mb-4">
        <mdb-row>
          <mdb-col md="4">
            <mdb-card>
              <mdb-card-body>
                <h4 class="card-title">전월 수강생수</h4>
                <h2>120 명</h2>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
          <mdb-col md="4">
            <mdb-card>
              <mdb-card-body>
                <h4 class="card-title">금월 수강생수</h4>
                <h2>135 명</h2>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
          <mdb-col md="4">
            <mdb-card>
              <mdb-card-body>
                <h4 class="card-title">전년대비 전월 수강생 증감률</h4>
                <h2>+12%</h2>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </div>
      <div class="graph-section">
        <mdb-row>
          <mdb-col md="6">
            <mdb-line-chart :data="flightHoursData" :options="chartOptions"></mdb-line-chart>
            <h4 class="graph-title">전년 대비 월별 비행시간</h4>
            <!-- <p class="graph-description">이 그래프는 현재와 전년 비행시간을 비교하여 월별 비행시간의 변화를 시각화합니다.</p> -->
          </mdb-col>
          <mdb-col md="6">
            <mdb-bar-chart :data="studentCountData" :options="barChartOptions"></mdb-bar-chart>
            <h4 class="graph-title">전년 대비 월별 수강생 수</h4>
            <!-- <p class="graph-description">이 그래프는 현재와 전년 수강학생 수를 비교하여 월별 학생 수의 변화를 시각화합니다.</p> -->
          </mdb-col>
        </mdb-row>
      </div>
    </mdb-container>
  </div>
</template>

<script>
import axios from "@/axios";
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardBody, mdbLineChart, mdbBarChart } from "mdbvue";
import Swal from 'sweetalert2';

export default {
  name: 'home',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbLineChart,
    mdbBarChart,
  },
  data() {
    return {
      token: this.$store.state.token.access_token,
      userInfo: {},
      currentDate: new Date(),
      currentTime: new Date().toLocaleString(),
      weather: {
        areaData:{},
        NoAreaData: false,
        today:{},
        tomorrow: {},
      },
      flightHoursData: {
        labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월'],
        datasets: [
          {
            label: '금년 비행시간',
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            data: [40, 55, 60, 61, 56, 55, 62],
          },
          {
            label: '전년 비행시간',
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(153,102,255,1)',
            data: [35, 50, 55, 57, 52, 50, 58],
          }
        ],
      },
      studentCountData: {
        labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월'],
        datasets: [
          {
            label: '금년 수강생 수',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            data: [30, 45, 50, 55, 60, 65, 70],
          },
          {
            label: '전년 수강생 수',
            backgroundColor: 'rgba(255,159,64,0.2)',
            borderColor: 'rgba(255,159,64,1)',
            data: [25, 40, 45, 50, 55, 60, 65],
          }
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };
  },
  mounted() {
    this.init();
    //this.getLocationAndFetchWeather();
    this.getLocatonfromStore();
    setInterval(() => {
      this.currentDate = this.formatDate(new Date());
      this.currentTime = this.formatTime(new Date());
    }, 1000);
  },
  methods: {
    init() {
      this.getUserInfo();
    },
    getUserInfo() {
      const config = {
        method: 'get',
        url: '/admins/me',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      };

      axios(config)
        .then((res) => {
          this.userInfo = { ...res.data };
        })
        .catch(() => {
          this.$swal
            .fire({ title: '정보없음', text: '로그아웃 되었습니다.', icon: 'error' })
            .then(() => {
              delete localStorage.token;
              this.$router.replace({ path: '/admin-login' }).catch(() => true);
            });
        });
    },
    TodaygetSkyConditionName(skyCode) {
      
      const skyConditionMap = {
        '1': '맑음',
        '2': '구름조금',
        '3': '구름많음',
        '4': '흐림',
        // 필요한 다른 코드도 추가할 수 있습니다.
      };
      return skyConditionMap[skyCode] || '알 수 없음';
    },
    TodaygetSkyConditionImage(skyCode, time) {
      const isDaytime =this.isDaytime(time);
      const skyImageMapDayTime = {
        '1': require('@/assets/images/weather/w00.jpg'),
        '2': require('@/assets/images/weather/w04.jpg'),
        '3': require('@/assets/images/weather/w05.jpg'),
        '4': require('@/assets/images/weather/w06.jpg'),
        // 필요한 다른 이미지도 추가할 수 있습니다.
      };
      const skyImageMapNightTime = {
        '1': require('@/assets/images/weather/w00-1.jpg'),
        '2': require('@/assets/images/weather/w04-1.jpg'),
        '3': require('@/assets/images/weather/w05-1.jpg'),
        '4': require('@/assets/images/weather/w06-1.jpg'),
        // 필요한 다른 이미지도 추가할 수 있습니다.
      };
      if(isDaytime){
        return skyImageMapDayTime[skyCode] || require('@/assets/images/weather/w00.jpg');
      }else{
        return skyImageMapNightTime[skyCode] || require('@/assets/images/weather/w00.jpg');
      }
    
  },

 
//   1	맑음	@/assets/images/weather/w00.jpg'
// 2	맑고 한때 비	@/assets/images/weather/w10.jpg'
// 3	구름많음	@/assets/images/weather/w05.jpg'
// 4	구름많고 비	@/assets/images/weather/w07.jpg'
// 5	구름많고 비 곳	@/assets/images/weather/w07.jpg'
// 6	구름많고 한때 비	@/assets/images/weather/w07.jpg'
// 7	구름많고 한때 비 곳	@/assets/images/weather/w07.jpg'
// 8	구름많고 가끔 비	@/assets/images/weather/w07.jpg'
// 9	구름많고 한때 소나기	@/assets/images/weather/w09.jpg'
// 10	구름많고 한때 소나기 곳	@/assets/images/weather/w09.jpg'
// 11	구름많고 한때 비/눈	@/assets/images/weather/w11.jpg'
// 12	구름많고 가끔 비/눈	@/assets/images/weather/w11.jpg'
// 13	구름많고 한때 눈 곳	@/assets/images/weather/w15.jpg'
// 14	흐림	@/assets/images/weather/w06.jpg'
// 15	흐리고 비	@/assets/images/weather/w14.jpg'
// 16	흐리고 한때 비	@/assets/images/weather/w14.jpg'
// 17	흐리고 한때 비 곳	@/assets/images/weather/w14.jpg'
// 18	흐리고 가끔 비	@/assets/images/weather/w14.jpg'
// 19	흐리고 소나기	@/assets/images/weather/w09.jpg'
// 20	흐리고 한때 소나기	@/assets/images/weather/w09.jpg'
// 21	흐리고 한때 소나기 곳	@/assets/images/weather/w09.jpg'
// 22	흐리고 가끔 소나기	@/assets/images/weather/w09.jpg'
// 23	흐리고 한때 눈	@/assets/images/weather/w13.jpg'
// 24	흐리고 가끔 눈	@/assets/images/weather/w13.jpg'
// 25	흐리고 한때 비/눈	@/assets/images/weather/w11.jpg'
// 26	흐리고 한때 비/눈 곳	@/assets/images/weather/w11.jpg'


  //   TommorwgetSkyConditionName(skyCode) {
      
  //   const skyConditionMap = {
  //     'DB01': '맑음',
  //     'DB02': '구름조금',
  //     'DB03': '구름많음',
  //     'DB04': '흐림',
  //     // 필요한 다른 코드도 추가할 수 있습니다.
  //   };
  //   return skyConditionMap[skyCode] || '알 수 없음';
  // },
  // getSkyConditionImage(skyCode) {
  //   const skyImageMap = {
  //     'DB01': require('@/assets/images/weather/w00.jpg'),
  //     'DB02': require('@/assets/images/weather/w04.jpg'),
  //     'DB03': require('@/assets/images/weather/w05.jpg'),
  //     'DB04': require('@/assets/images/weather/w06.jpg'),
  //     // 필요한 다른 이미지도 추가할 수 있습니다.
  //   };
  //   return skyImageMap[skyCode] || require('@/assets/images/weather/w00.jpg');
  // },

// 내일 오전/오후 날씨 아이콘을 반환하는 함수
getSkyConditionImage(skyCode, skyName) {
    const skyImageMapByName = {
      '맑음': require('@/assets/images/weather/w00.jpg'),
      '맑고 한때 비': require('@/assets/images/weather/w10.jpg'),
      '구름많음': require('@/assets/images/weather/w05.jpg'),
      '구름많고 비': require('@/assets/images/weather/w07.jpg'),
      '구름많고 비 곳': require('@/assets/images/weather/w07.jpg'),
      '구름많고 한때 비': require('@/assets/images/weather/w07.jpg'),
      '구름많고 한때 비 곳': require('@/assets/images/weather/w07.jpg'),
      '구름많고 가끔 비': require('@/assets/images/weather/w07.jpg'),
      '구름많고 한때 소나기': require('@/assets/images/weather/w09.jpg'),
      '구름많고 한때 소나기 곳': require('@/assets/images/weather/w09.jpg'),
      '구름많고 한때 비/눈': require('@/assets/images/weather/w11.jpg'),
      '구름많고 가끔 비/눈': require('@/assets/images/weather/w11.jpg'),
      '구름많고 한때 눈 곳': require('@/assets/images/weather/w15.jpg'),
      '흐림': require('@/assets/images/weather/w06.jpg'),
      '흐리고 비': require('@/assets/images/weather/w14.jpg'),
      '흐리고 한때 비': require('@/assets/images/weather/w14.jpg'),
      '흐리고 한때 비 곳': require('@/assets/images/weather/w14.jpg'),
      '흐리고 가끔 비': require('@/assets/images/weather/w14.jpg'),
      '흐리고 소나기': require('@/assets/images/weather/w09.jpg'),
      '흐리고 한때 소나기': require('@/assets/images/weather/w09.jpg'),
      '흐리고 한때 소나기 곳': require('@/assets/images/weather/w09.jpg'),
      '흐리고 가끔 소나기': require('@/assets/images/weather/w09.jpg'),
      '흐리고 한때 눈': require('@/assets/images/weather/w13.jpg'),
      '흐리고 가끔 눈': require('@/assets/images/weather/w13.jpg'),
      '흐리고 한때 비/눈': require('@/assets/images/weather/w11.jpg'),
      '흐리고 한때 비/눈 곳': require('@/assets/images/weather/w11.jpg')
    };

    const skyImageMapByCode = {
      'DB01': require('@/assets/images/weather/w00.jpg'),
      'DB02': require('@/assets/images/weather/w04.jpg'),
      'DB03': require('@/assets/images/weather/w05.jpg'),
      'DB04': require('@/assets/images/weather/w06.jpg')
    };

    // 이중 인용부호 제거
    const cleanedSkyName = (skyName && typeof skyName === 'string') ? skyName.replace(/"/g, '') : '';

    //console.log(`Checking cleaned skyName: '${cleanedSkyName}'`);

    // SkyName으로 매핑된 이미지 찾기
    if (skyImageMapByName[cleanedSkyName.trim()]) {
      //console.log("MapbyName");
      return skyImageMapByName[cleanedSkyName];
    }

    //console.log("SkyName not found in map. Checking skyCode...");
    // SkyCode로 매핑된 이미지 찾기
    return skyImageMapByCode[skyCode] || require('@/assets/images/weather/w00.jpg');
  },

  // 내일 오전 아이콘을 가져오는 함수
  getTomorrowAmIcon() {
    return this.getSkyConditionImage(this.weather.tomorrow.amSkyCode, this.weather.tomorrow.amSkyName);
  },

  // 내일 오후 아이콘을 가져오는 함수
  getTomorrowPmIcon() {
    return this.getSkyConditionImage(this.weather.tomorrow.pmSkyCode, this.weather.tomorrow.pmSkyName);
  },
  
  isDaytime(time) {
    const hours = time.getHours();
    return hours >= 6 && hours < 18;
  },

  //https환경과 localhost로 테스트시에만 데이터를 가져올 수 있다. 
 //그 외에는 데이터를 가져올 수 없기에 사용불가...
    getLocationAndFetchWeather() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;
          this.fetchWeather(latitude, longitude);
        }, error => {
          console.error('Error getting location:', error);
          // Default location if user denies access to location
          this.fetchWeather(37.4896527777777, 126.681877777777); // 인천
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
        // Default location if geolocation is not supported
        this.fetchWeather(37.4896527777777, 126.681877777777); // 인천
      }
    },

    //지도에서 저장한 위치정보를 가져온다.
    getLocatonfromStore(){
      
      this.$store.dispatch('fetchLocation').then(() => {
      const location = this.$store.getters.currentLocation;
      if (location.lat && location.lng) {
        const cachedWeather =this.$store.state.weather;
        if(cachedWeather){
          this.weather = cachedWeather; 
          //console.log('cachedWeather=', JSON.stringify(this.weather, null, 2));        
          
        }
        this.fetchWeather(location.lat, location.lng);
        
      } else {
        this.getLocationAndFetchWeather();
      }
    });
      
    },
    fetchWeather(latitude, longitude) {
      const config = {
        method: 'get',
        url: '/weather',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`
        },
        params: { longitude:longitude , latitude: latitude }
      };
        axios(config)
        .then(response => {
          const data = response.data;
          this.weather = {
            areaData:{
              city: data.areaData.city,              
              gu: data.areaData.gu,
              city_district: data.areaData.city_district,
              dong: data.areaData.dong,
              quarter: data.areaData.quarter,
              county: data.areaData.county,
              town: data.areaData.town,
              village: data.areaData.village
            },
            NoAreaData: data.NoAreaData,
            today:{
              temperature: data.todayWeather.temperature,
              skyCondition: data.todayWeather.skyCondition,
              waterType: data.todayWeather.waterType,
              precipitationProbability: data.todayWeather.precipitationProbability,
              windDirection: data.todayWeather.windDirection,
              windSpeed: data.todayWeather.windSpeed,
              sunrise: data.todayWeather.sunrise,
              sunset: data.todayWeather.sunset,
            },
            tomorrow:{
              amTemp: data.tomorrowWeather.amTemp,
              pmTemp: data.tomorrowWeather.pmTemp,
              amPrep: data.tomorrowWeather.amPrep,
              pmPrep: data.tomorrowWeather.pmPrep,
              amSkyCode: data.tomorrowWeather.amSkyCode,
              pmSkyCode: data.tomorrowWeather.pmSkyCode,
              amSkyName: data.tomorrowWeather.amSkyName.replace(/"/g, ''),
              pmSkyName: data.tomorrowWeather.pmSkyName.replace(/"/g, ''),
              amPrepType: data.tomorrowWeather.amPrepType,
              pmPrepType: data.tomorrowWeather.pmPrepType,
              sunrise: data.tomorrowWeather.sunrise,
              sunset: data.tomorrowWeather.sunset,
            }
            
          };
          
          this.$store.dispatch('setWeatherData', this.weather);
          
          if (this.weather.NoAreaData) {
            Swal.fire({
              icon: 'warning',
              title: '예보구간코드를 찾지 못했습니다.',
              text: '해당지역의 예보구간코드를 찾지 못했습니다. 재설정 요망'
            });
          }
        })
        .catch(error => {
          console.error('날씨 정보를 가져오는 데 실패했습니다:', error);
          
            Swal.fire({
              icon: 'warning',
              title: '예보구간코드를 찾지 못했습니다.',
              text: '위치를 재설정 하세요!'
            });
          
        });
    },
    // fetchWeather() {
    //   const apiKey = 'ef9f2c3510c38820472e287e4436f2c4';  // 여기에 OpenWeatherMap API 키를 입력하세요
    //   const city = 'Seoul';  // 여기에 원하는 도시를 입력하세요
    //   axios.get(`/weather/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`)
    //     .then(response => {
    //       const data = response.data;
    //       this.weather = {
    //         city: data.name,
    //         temp: data.main.temp,
    //         description: data.weather[0].description,
    //       };
    //     })
    //     .catch(error => {
    //       console.error('날씨 정보를 가져오는 데 실패했습니다:', error);
    //     });
    // },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
      const dayOfWeek = daysOfWeek[date.getDay()];
      return `${year}.${month}.${day} (${dayOfWeek})`;
      
    },
    formatTime(date) {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const ampm = hours >= 12 ? '오후' : '오전';
      hours = hours % 12;
      hours = hours ? hours : 12; // 0을 12로 바꿈
      return `${ampm} ${hours}:${minutes}:${seconds}`;
    },
    navigateToMap() {
      this.$router.push({ name: 'map', query: { fromHome: 'true' } }); // 문자열 'true'로 설정
    }
  },
};
</script>

<style scoped>
.main-container {
  padding: 15px;
  background-color: rgb(242, 255, 227);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.logo-title{
  font-size: 1.0em;
  font-weight: bold;
  margin-bottom: 10px;
}
.logo-image {
  width: 1em; /* 글자 사이즈에 맞추기 위해 em 단위를 사용합니다 */
  height: auto;
  margin-right: 4px; /* 이미지와 텍스트 사이의 간격 조정 */
}
.location-button {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.location-button:hover {
  background-color: #0056b3;
}
 .pageTitle {
  font-weight: bold;
  text-align: center;
  color: #555555; /* 텍스트 색상 */
 }
 
.weather-block {
  border: 2px solid #d3d3d3; /* 연한 회색 테두리 */
  border-radius: 10px; /* 둥근 모서리 */
  padding: 15px; /* 내부 여백 */
  background-color: white; /* 배경색 (선택 사항) */
}
.weather-info {
  text-align: center;
  margin-bottom: 5px;
}

.weather-info h4 {
  margin-bottom: 10px;
}

.weather-info p {
  margin: 5px 0;
  text-align: center;
}

.home-container {
  padding-top: 30px;
}

.digital-clock {
  color:rgb(98, 87, 255);
  font-size: 2em;
  font-weight: normal;
  
}
.date-column{
  text-align: left;
}
.time-column{
  text-align: right;
}
.card-section {
  margin-bottom: 20px;
}

.card-title {
  text-align: center;
}

.graph-section {
  margin-top: 40px;
}

.graph-title {
  text-align: center;
  margin-bottom: 15px;
}

.graph-description {
  text-align: center;
  margin-top: 10px;
  color: #555;
}
.tomorrow-weather-row {
  display: flex;
  align-items: center;
}

.weather-column {
  flex: 1;
  text-align: center;
}

.vertical-divider {  
  border-left : thin solid #c8cbcd;
  height: 100PX;
  margin: 0 10px;
}
</style>